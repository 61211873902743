import React, { Component } from "react";
import Header from '../Components/Fixed/Header';
import Footer from '../Components/Fixed/Footer';
import HomeData from '../Components/Fixed/HomeData';
import EventDialog from '../Components/Dialog/EventDialog';
import { useState,useEffect } from 'react';

function Home() {
    const [open, setOpen] = useState(true);

    useEffect(() => {
        // const date = new Date();
        // if(date.getFullYear() < 2025 || (date.getFullYear() === 2025 && date.getMonth() < 1) || (date.getFullYear() === 2025 && date.getMonth() === 1 && date.getDate() < 24)){
        // const savedValue = localStorage.getItem('doNotShowAgain');
        // if(savedValue === "true"){
        //     setOpen(false)
        // }else{
        //         setOpen(true);
        //     }
        // }
    }, []);
    return (
        <div className="max-w-lg mx-auto">
            <Header/>
            <HomeData />
            {/* <EventDialog open={open} setOpen={setOpen} /> */}
            <Footer />
        </div>

    );
}

export default Home;


